import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, first, switchMap, timeout } from "rxjs/operators";

import { AuthenticationService } from "@core/services/authentication/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuardService implements CanActivate, CanLoad {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router
  ) {}

  public canActivate(): Observable<boolean> {
    return this.shouldPass();
  }

  public canLoad(): Observable<boolean> {
    return this.shouldPass();
  }

  private shouldPass(): Observable<boolean> {
    return this.authService.token$.pipe(
      first((token) => !!token),
      timeout(2000),
      switchMap(() => {
        return of(true);
      }),
      catchError(() => {
        this.router.navigateByUrl("/login");
        return of(false);
      })
    );
  }
}
