<div class="h-screen flex flex-col justify-center items-center">
  <div class="mb-xs">
    <h1 class="text-center">Progressive Loss Viewer Login</h1>
  </div>

  <div class="min-h-lg">
    <mat-card class="w-40 overflow-hidden">
      @if (errorMessage) {
      <cla-error-prompt
        [errorMessage]="errorMessage"
        (onClose)="clearError()"
      ></cla-error-prompt>
      } @else if (!this.ssoLogin) {
      <div>
        <div class="flex flex-col space-y-xxs">
          <button
            mat-flat-button
            class="dialog-confirm"
            color="primary"
            (click)="handleCredentialAuth()"
            data-pgr-id="btnGoToCredentialLogin"
          >
            Login with Credentials
          </button>

          <button
            mat-flat-button
            class="dialog-confirm"
            color="primary"
            (click)="showSSOPrompt()"
            data-pgr-id="btnGoToSsoLogin"
          >
            Login with SSO
          </button>
        </div>
      </div>

      } @else {
      <cla-sso-prompt
        (onCancel)="hideSSOPrompt()"
        (onError)="displayError($event)"
      ></cla-sso-prompt>
      }
    </mat-card>
  </div>
</div>
