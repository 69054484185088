<div>
  <div
    class="flex items-center mb-xs cursor-pointer"
    (click)="handleClose()"
    data-pgr-id="btnGoBackFromError"
  >
    <mat-icon>arrow_back</mat-icon>
    <span>Go Back</span>
  </div>
  <div data-pgr-id="txtErrorContent">{{ errorMessage() }}</div>
</div>
