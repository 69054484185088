import { Injectable } from "@angular/core";
import { ClaimsSummaryConfigInfo } from "@modules/shared";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { first, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class IdpService {
  private configuration: ClaimsSummaryConfigInfo;

  constructor(private configurationService: ConfigurationService) {
    this.configurationService.configuration$
      .pipe(
        first((x) => !!x),
        map((x: ClaimsSummaryConfigInfo) => x)
      )
      .subscribe((c) => (this.configuration = c));
  }

  public getIdpForClient(client: string): string | null {
    return this.getIdpFromConfig(this.configuration, client);
  }

  public getValidClients(): string[] {
    return Object.keys(this.configuration.clientIdps);
  }

  private getIdpFromConfig(
    config: ClaimsSummaryConfigInfo,
    client: string
  ): string | null {
    return config.clientIdps[client] ?? null;
  }
}
