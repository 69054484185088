import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CookieConstants } from "@shared/constants";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "cla-claim-header-agreement-dialog",
  templateUrl: "claim-header-agreement-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimHeaderAgreementDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ClaimHeaderAgreementDialogComponent>,
    public cookieService: CookieService
  ) {}

  onConfirm(): void {
    const expirationDate: Date = new Date();
    expirationDate.setHours(expirationDate.getHours() + 12);
    this.cookieService.set(
      CookieConstants.agreement,
      "true",
      expirationDate,
      "/",
      undefined,
      undefined,
      "Strict"
    );
    this.dialogRef.close();
  }
}
