import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DiaryModule } from "@modules/diary/diary.module";
import { DiaryStateService } from "@modules/diary/services";
import { NoteListModule } from "@modules/notes/modules/note-list/note-list.module";
import { NotesService } from "@modules/notes/services";
import { NotesStateService } from "@modules/notes/state";
import { SharedModule } from "@modules/shared/shared.module";
import { EffectsModule } from "@ngrx/effects";
import { WindowManagementService } from "@pgr-cla/cla-window";
import { DialogService } from "@pgr-cla/core-ui-components";
import { ClaimsLandingComponent } from "./claims-landing.component";
import { ClaimsLandingRoutingModule } from "./claims-landing.routing.module";
import {
  AlertsBarComponent,
  ClaimHeaderAgreementDialogComponent,
  ClaimHeaderComponent,
  ClaimHeaderDataComponent,
  ClaimHeaderLogoComponent,
  ClaimHeaderSearchDialogComponent,
  ClaimHeaderSearchWrapperComponent,
  ClaimStatusIndicatorComponent,
  ClaimSubHeaderComponent,
  LeftRailComponent,
  LeftRailPartyPropertyComponent,
  LeftRailSectionHeadingComponent,
  LossDetailsComponent,
  SearchableClaimNotFoundComponent,
  SurchargeIndicatorComponent,
} from "./components";
import { TreePartyContactCardComponent } from "./components/left-rail/components/tree-party-contact-card/tree-party-contact-card.component";
import { LeftAccessibleResizeHandleDirective } from "./directives/left-accessible-resize-handle.directive";
import { RightAccessibleResizeHandleDirective } from "./directives/right-accessible-resize-handle.directive";
import { ClaimsLandingEffects } from "./effects";
import {
  ActivityWebEventService,
  CancelledClaimMessageResolver,
  ClaimsLandingStateService,
  LeftRailResizeService,
  PreliminaryClaimMessageResolver,
  RestrictedClaimMessageResolver,
  RightRailResizeService,
  ScreenSizeService,
} from "./services";

@NgModule({
  imports: [
    ClaimsLandingRoutingModule,
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([ClaimsLandingEffects]),
    DiaryModule,
    NoteListModule,
  ],
  declarations: [
    AlertsBarComponent,
    ClaimHeaderComponent,
    ClaimHeaderDataComponent,
    ClaimHeaderLogoComponent,
    ClaimHeaderSearchDialogComponent,
    ClaimHeaderAgreementDialogComponent,
    ClaimHeaderSearchWrapperComponent,
    ClaimSubHeaderComponent,
    ClaimsLandingComponent,
    LeftAccessibleResizeHandleDirective,
    LeftRailComponent,
    LeftRailPartyPropertyComponent,
    LeftRailSectionHeadingComponent,
    LossDetailsComponent,
    RightAccessibleResizeHandleDirective,
    SearchableClaimNotFoundComponent,
    TreePartyContactCardComponent,
    SurchargeIndicatorComponent,
    ClaimStatusIndicatorComponent,
  ],
  providers: [
    LeftRailResizeService,
    RightRailResizeService,
    ScreenSizeService,
    CancelledClaimMessageResolver,
    PreliminaryClaimMessageResolver,
    RestrictedClaimMessageResolver,
    WindowManagementService,
    DiaryStateService,
    DialogService,
    ClaimsLandingStateService,
    NotesService,
    NotesStateService,
    ActivityWebEventService,
  ],
  exports: [],
})
export class ClaimsLandingModule {}
