import {
  Component,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
} from "@angular/core";

@Component({
  selector: "cla-error-prompt",
  templateUrl: "./error-prompt.component.html",
  styleUrl: "./error-prompt.component.scss",
})
export class ErrorPromptComponent {
  readonly errorMessage: InputSignal<string> = input.required<string>();
  readonly onClose: OutputEmitterRef<void> = output<void>();

  handleClose(): void {
    this.onClose.emit();
  }
}
