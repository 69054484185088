import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { CookieConstants } from "@shared/constants";
import { CookieService } from "ngx-cookie-service";
import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ClaimGuardService } from "../claim-guard";
import { ClaimStatusGuardService } from "../claim-status-guard";

@Injectable()
export class CombinedClaimGuard implements CanActivate {
  constructor(
    private claimGuard: ClaimGuardService,
    private claimStatusGuard: ClaimStatusGuardService,
    private router: Router,
    private cookieService: CookieService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.cookieService.get(CookieConstants.agreement) !== "true") {
      return of(this.router.parseUrl("/"));
    }
    return this.claimGuard.canActivate(route).pipe(
      mergeMap((firstGuardSuccessful: boolean) => {
        if (firstGuardSuccessful) {
          return this.claimStatusGuard.canActivate(route);
        }
        return of(this.router.parseUrl("/"));
      })
    );
  }
}
