import { Component, output, OutputEmitterRef } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AuthenticationService } from "@modules/core";
import { IdpService } from "@modules/core/services/authentication/idp.service";

@Component({
  selector: "cla-sso-prompt",
  templateUrl: "./sso-prompt.component.html",
  styleUrl: "./sso-prompt.component.scss",
})
export class SsoPromptComponent {
  readonly onCancel: OutputEmitterRef<void> = output<void>();
  readonly onError: OutputEmitterRef<string> = output<string>();

  showEmailField: boolean = false;

  public emailControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    private authService: AuthenticationService,
    private idpService: IdpService
  ) {}

  handleCancel(): void {
    this.onCancel.emit();
  }

  handleSSOAuth() {
    if (this.isValidEmail) {
      const client: string | undefined = this.getClientFromInput();

      if (client && !this.idpService.getValidClients().includes(client)) {
        this.onError.emit("SSO is not available at this time.");
        return;
      }

      this.authService.authorize(client);
    }
  }

  getClientFromInput(): string | undefined {
    if (this.email) {
      return this.email.substring(this.email.lastIndexOf("@") + 1);
    }
  }

  get isValidEmail(): boolean | undefined {
    return this.emailControl?.valid;
  }

  get email(): string | undefined {
    return this.emailControl?.value;
  }
}
