import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Renderer2,
} from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { Router, RouterOutlet } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { PageLoadPerformanceService } from "@pgr-cla/cla-analytics";
import {
  ClaimNumberPipe,
  StyleManagerService,
} from "@pgr-cla/core-ui-components";
import FontFaceObserver from "fontfaceobserver";

import { AppContextService } from "@modules/core/services";

@UntilDestroy()
@Component({
  selector: "cla-app",
  templateUrl: "./app.component.html",
  providers: [ClaimNumberPipe, Title, StyleManagerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public isPreloaderVisible: boolean = true;

  constructor(
    router: Router,
    private readonly renderer: Renderer2,
    private readonly title: Title,
    private readonly styleManagerService: StyleManagerService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly appContextService: AppContextService,
    private readonly pageLoadPerformanceService: PageLoadPerformanceService
  ) {
    router.setUpLocationChangeListener();

    this.pageLoadPerformanceService.initialize(this.appContextService.context$);

    this.toggleTheme("light");

    this.matIconRegistry.addSvgIcon(
      "edit_circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/edit-circle.svg"
      )
    );

    this.matIconRegistry.addSvgIcon(
      "no_doc_found",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/document-not-found.svg"
      )
    );

    this.matIconRegistry.addSvgIcon(
      "attorney",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/attorney.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "binoculars",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/binoculars.svg"
      )
    );

    this.matIconRegistry.addSvgIcon(
      "contact-pending",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/contact-pending.svg"
      )
    );

    this.matIconRegistry.addSvgIcon(
      "contacted",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/contacted.svg"
      )
    );

    // prevent flash of unstyled content with icon fonts
    // https://github.com/angular/components/issues/12171
    const materialIcons: FontFaceObserver = new FontFaceObserver(
      "Material Icons"
    );
    materialIcons
      .load(null, 10000)
      .then(() =>
        this.renderer.addClass(document.body, "material-icons-loaded")
      )
      .catch(() =>
        this.renderer.addClass(document.body, "material-icons-error")
      );

    const materialIconsOutlined: FontFaceObserver = new FontFaceObserver(
      "Material Icons Outlined"
    );
    materialIconsOutlined
      .load(null, 10000)
      .then(() =>
        this.renderer.addClass(document.body, "material-icons-outlined-loaded")
      )
      .catch(() =>
        this.renderer.addClass(document.body, "material-icons-outlined-error")
      );
  }

  public onRouteChange(outlet: RouterOutlet): void {
    this.isPreloaderVisible = false;
    if (!outlet?.activatedRouteData?.useTitleService) {
      this.title.setTitle(outlet?.activatedRouteData?.title);
    }
  }

  public toggleTheme(theme: "light" | "dark"): void {
    this.styleManagerService.setStyle("theme", `${theme}-blue.css`).subscribe();
  }

  @HostListener("window:pageshow", ["$event"])
  public pageShow($event: PageTransitionEvent): void {
    if ($event.persisted) {
      location.reload();
    }
  }
}
