<div class="flex flex-col">
  <div
    class="flex items-center mb-xs cursor-pointer"
    (click)="handleCancel()"
    data-pgr-id="btnGoBackFromSso"
  >
    <mat-icon>arrow_back</mat-icon>
    <span>Go Back</span>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input
      matInput
      placeholder="Email"
      (keyup.enter)="handleSSOAuth()"
      [formControl]="emailControl"
      required
      data-pgr-id="textInputEmail"
    />
    <mat-icon (click)="handleSSOAuth()" matSuffix>arrow_forward</mat-icon>
  </mat-form-field>

  <button
    mat-flat-button
    class="dialog-confirm"
    color="primary"
    (click)="handleSSOAuth()"
    [disabled]="!this.isValidEmail"
    data-pgr-id="btnSsoLoginSubmit"
  >
    Login
  </button>
</div>
