<h2 mat-dialog-title id="lossviewer-agreement-header">
  PRIOR TO USE OF LOSS VIEWER PORTAL
</h2>
<mat-dialog-content id="lossviewer-agreement-description">
  <p>
    PLEASE ACKNOWLEDGE AND AGREE TO THE FOLLOWING RESTRICTIONS ON THE USE OF
    LOSS VIEWER:
  </p>
  <ol>
    <li>Use of Loss Viewer shall be limited to read-only</li>
    <li>
      You shall not copy, download, print, screen capture, or otherwise
      reproduce any information accessed via Loss Viewer
    </li>
    <li>
      You shall not share your screen or otherwise transmit to others the
      information accessed via Loss Viewer
    </li>
    <li>
      You shall not access, view, or use any information via Loss Viewer other
      than for necessary business purposes
    </li>
  </ol>
  <p>
    ALL INFORMATION ACCESSED VIA LOSS VIEWER IS STRICTLY CONFIDENTIAL AND SHALL
    NOT BE SHARED WITH ANY UNAUTHORIZED INDIVIDUALS
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mt-s">
  <button
    mat-flat-button
    mat-dialog-close
    color="primary"
    class="dialog-confirm"
    data-pgr-id="btnDialogConfirm"
    (click)="onConfirm()"
  >
    Agree
  </button>
</mat-dialog-actions>
