import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@modules/core";

@Component({
  selector: "cla-login-page",
  templateUrl: "./login-page.component.html",
  styleUrl: "./login-page.component.scss",
})
export class LoginPageComponent {
  errorMessage: string | null = null;
  ssoLogin: boolean = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router
  ) {
    if (!this.authService.isTokenCookieExpired) {
      this.router.navigateByUrl("/welcome");
    }
  }

  handleCredentialAuth() {
    this.authService.authorize();
  }

  showSSOPrompt() {
    this.ssoLogin = true;
  }

  hideSSOPrompt() {
    this.ssoLogin = false;
  }

  displayError(error: string) {
    this.errorMessage = error;
  }

  clearError() {
    this.errorMessage = null;
  }
}
